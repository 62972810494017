import nft from "../assets/nft.gif"

const Jpeg = () => {
    return ( 

        <img src={nft} class="p-4 md:mx-16 md:h-[32rem] md:w-[32rem]"/>

     );
}
 
export default Jpeg;