import Header from "../components/Header";
import Jpeg from "../components/Jpeg";
import Description from "../components/Description";
import Footer from "../components/Footer";
import Banner from "../components/Banner";

const Mint = () => {

  return (
    <div>

      <Header />

      {/* <Banner /> */}

      <div class="pb-24 md:flex md:flex-row md:mx-40">

        <Jpeg />

        <Description />

      </div>

      <Footer />

    </div>

  );
}

export default Mint;
