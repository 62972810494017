import { kitAddress, kitAbi, airportAddress, airportAbi, registryAddress, registryAbi } from "../abis/contracts";
import * as contents from "../abis/contents";
import { useContract, useAddress, Web3Button } from "@thirdweb-dev/react";
import { ethers } from "ethers";

const Buy = () => {

    // initiate connected address and kit contract

    const connectedAddress = useAddress()
    const { contract, isLoading } = useContract(kitAddress, kitAbi)
    const { contract: registryContract, isLoading: registryLoading } = useContract(registryAddress, registryAbi)
    const { contract: airportContract, isLoading: airportLoading } = useContract(airportAddress, airportAbi)

    const prepareMint = async () => {

        // check for contract readiness and connected address

        if (isLoading === false && connectedAddress && registryLoading === false && airportLoading === false) {

            // get kits owned and mint next id, due to contract single mint bug

            const tokenCount = await contract.call("tokensOfOwner", [airportAddress])
            const tokenId = tokenCount[0].toString()

            console.log("Minting token " + tokenId)

            // get tokenbound account of kit id

            const acc = await registryContract.call("account", ["0x2D25602551487C3f3354dD80D76D54383A243358", 8453, kitAddress, tokenId, 0])
            console.log("Sending kit contents of account " + acc)

            // format item calldata to be sent

            const baseProvider = new ethers.providers.JsonRpcProvider("https://mainnet.base.org")
            const baldContract = new ethers.Contract(contents.baldAddress, contents.baldAbi, baseProvider)

            const encode = await baldContract.populateTransaction.transferFrom("0x27d2decb4bfc9c76f0309b8e88dec3a601fe25a8", "0x27d2decb4bfc9c76f0309b8e88dec3a601fe25a8", 1000)
            console.log(encode)

            // call claimAndMint on airport

            await airportContract.call("claimAndMint", [tokenId, connectedAddress, acc, []], { value: ethers.utils.parseEther("0.069") })

        }

    }

    return (
        <Web3Button
            contractAddress={airportAddress}
            contractAbi={airportAbi}
            action={() => prepareMint()}
            onSuccess={(result) => console.log("Success " + result)}
            onError={() => console.log("Transaction rejected")}
        >
            Buy Kit
        </Web3Button>
    );
}

export default Buy;