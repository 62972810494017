const Banner = () => {
    return ( 
        <div class="flex place-content-center items-center h-10 w-full bg-slate-800">

            <p class="text-white text-sm">Join the <a href="https://onchainsummer.xyz/" target="_blank" class="hover:text-sky-400">OnChain Summer Party</a> on Base 🏖️</p>

        </div>
     );
}
 
export default Banner;