import Footer from "../components/Footer";
import Header from "../components/Header";

const About = () => {
    return (
        <div>

            <Header />

            {/* self shill */}

            <div class="flex flex-col items-center space-y-6 my-40 justify-center">
                <h1 class="text-6xl font-[700] font-bold">#Web3StarterKit</h1>

                <p class="text-slate-600">Onboarding the next billion, one kit at a time with a welcoming experience to web3</p>
                <p class="text-slate-600">Built with thought 🙌 by <a href="https://twitter.com/0xV4L3NT1N3" target="_blank" class="text-sky-600 hover:text-sky-400">@0xV4L3NT1N3</a></p>

                {/* kit as a service */}

                <a href="https://eips.ethereum.org/EIPS/eip-6551" target="_blank" class="border border-2 border-slate-600 rounded-md px-4 py-2">Learn more about ERC6551</a>

            </div>

            <Footer />

        </div>
    );
}

export default About;