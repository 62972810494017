import { useContract, useAddress } from "@thirdweb-dev/react";
import { useEffect, useState } from "react";
import { kitAddress, kitAbi, registryAddress, registryAbi, tokenAddress, tokenAbi } from "../abis/contracts";
import Card from "../components/Card";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import etherscan from "../assets/etherscan.png"
import link from "../assets/link.svg"

const Claim = () => {

    const connectedAddress = useAddress()
    const [showClaimable, setShowClaimable] = useState(true)
    const [allTokenIds, setAllTokenIds] = useState([])
    const [sortTokenIds, setSortTokenIds] = useState([])
    const { contract, isLoading } = useContract(kitAddress, kitAbi)
    const { contract: registryContract, isLoading: registryLoading } = useContract(registryAddress, registryAbi)
    const { contract: tokenContract, isLoading: tokenLoading } = useContract(tokenAddress, tokenAbi)
    const [status, setStatus] = useState()
    const [toggleStatus, setToggleStatus] = useState("Show Claimed NFTs")

    // load the page first, wait for contract to load and trip useEffect once true

    const getOwnedNFTs = async () => {

        if (isLoading === false) {

            if (connectedAddress) {
                // get kits owned

                const tokenCount = await contract.call("tokensOfOwner", [connectedAddress])
                setAllTokenIds(tokenCount)

                if (tokenCount.length === 0) {
                    setStatus("No kits owned, mint some!")
                }

            } else {
                setStatus("Connect wallet to view available kits.")
            }

        } else {
            setStatus("Loading ...")
        }

    }

    // check for ETH balance to determine if kit is claimed

    const checkClaimed = async () => {

        var accounts = []


        if (allTokenIds.length !== 0) {

            // get tokenbound account of each kit id

            for (let i = 0; i < allTokenIds.length; i++) {
                const acc = await registryContract.call("account", ["0x2D25602551487C3f3354dD80D76D54383A243358", 8453, kitAddress, allTokenIds[i], 0])
                accounts.push(acc)
            }

            // add kit id to sorted list if balance of primary token not transferred yet

            for (let i = 0; i < accounts.length; i++) {
                const balance = await tokenContract.call("balanceOf", [accounts[i]])

                if (balance > 0) {
                    setSortTokenIds(current => [...current, allTokenIds[i]])
                }

            }

        }

    }

    // toggle display and message

    const toggleClaimable = () => {

        setShowClaimable(!showClaimable)

        if (!showClaimable === true) {
            console.log("this truly fired" + !showClaimable)
            setToggleStatus("Show Claimed NFTs")
        } else {
            console.log("this fired" + !showClaimable)
            setToggleStatus("Hide Claimed NFTs")
        }
    }

    // render card list of kits owned 

    const returnTokenIds = showClaimable ? sortTokenIds.map(stuff => <Card id={stuff.toString()} />) : allTokenIds.map(stuff => <Card id={stuff.toString()} />)

    // format etherscan link with current address

    const explorer = "https://etherscan.io/address/" + connectedAddress
    
    // refresh kit display as wallet connected/disconnected

    useEffect(() => {
        getOwnedNFTs()
    }, [isLoading, connectedAddress])

    useEffect(() => {
        checkClaimed()
    }, [allTokenIds])

    return (
        <div>

            <Header />

            <Banner />

            {/* token count and toggle */}

            <div class="px-4 md:mx-40">

                <h1 class="text-4xl font-[600] my-4 font-bold">CLAIM</h1>

                <div class="flex items-center mb-8 space-x-2">
                    <img src={etherscan} class="h-6 w-6" />
                    <a href={explorer} target="_blank">View Portfolio</a>
                    <img src={link} class="h-3.5 w-3.5" />
                </div>

                <div class="flex justify-between">
                    <div class="flex space-x-2 items-center">

                        <p>Your Items</p>

                        {/* pita engineering to draw a shape */}

                        <div class="flex items-center justify-center">
                            <p class="absolute z-50 text-white font-medium">{showClaimable ? sortTokenIds.length : allTokenIds.length}</p>
                            <div class="bg-gray-900 w-6 h-8 rounded-md"></div>
                        </div>

                    </div>

                    <button class="text-sky-600 hover:text-sky-800 font-medium" onClick={() => toggleClaimable()}>{toggleStatus}</button>
                </div>

                <hr class="my-8" />
            </div>

            {/* cards of kits owned */}

            <div class="grid grid-cols-1 gap-4 pb-16 px-4 md:mx-40 md:grid-cols-3">
                {allTokenIds.length > 0 ? returnTokenIds : <p>{status}</p>}
            </div>

            <Footer />

        </div>
    );
}

export default Claim;