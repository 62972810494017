import { useState } from "react";
// import plus from "../assets/plus.svg"
// import minus from "../assets/minus.svg"
import Buy from "./Buy";
import base from "../assets/base.png"
import bald from "../assets/bald.png"
import eth from "../assets/eth.png"
import cbeth from "../assets/cbeth.png"
import dackie from "../assets/dackie.png"

const Description = () => {

    const [description, setDescription] = useState("Included in the pack:")

    // render list of kit images 

    const contents = [{ desc: "1 x Dackie Duck NFT", img: dackie }, { desc: "5 x Base Commitment Collection", img: base }, { desc: "1 x 0.003 Ether for gas", img: eth }, { desc: "1 x 0.003 Staked Ether", img: cbeth }, { desc: "500 x $BALD memecoin", img: bald }]

    const returnImages = contents.map(content =>
        <button onClick={() => setDescription(content.desc)}>
            <img src={content.img} class="w-20 h-20 border border-4 border-white rounded-lg hover:border-sky-200" alt="product-images" />
        </button>
    )

    return (
        <div class="flex flex-col space-y-6 px-4 md:justify-center">

            {/* kit descriptions */}

            <div>
                <h1 class="text-4xl md:text-5xl font-[700] bg-gradient-to-r from-[#0a59ff] to-[#fff8f8] text-transparent bg-clip-text">Base</h1>
                <h1 class="text-4xl md:text-5xl font-[700]">Starter Kit</h1>

                <p class="text-xl text-gray-600 mt-2">$69 | 0.042 ETH</p>
            </div>

            <p class="text-gray-600 text-justify">Get started with the <a href="https://twitter.com/BuildOnBase" target="_blank" rel="noreferrer" class="text-blue-600">Base chain</a> by getting a curated pack of ETH, NFTs for your profile pic and passive income tokens for your web3 adventure.</p>

            {/* kit contents */}

            <p class="text-gray-600 mb-4">{description}</p>

            <div class="grid grid-cols-4 md:grid-cols-5 pb-8">
                {returnImages}
            </div>

            {/* mint button, needs a whole other section for minting parameters */}

            <Buy />

        </div>
    );
}

export default Description;