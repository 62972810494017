import React from "react";
import { createRoot } from "react-dom/client";
import { ThirdwebProvider } from "@thirdweb-dev/react";
import "./index.css";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Mint from "./pages/Mint";
import Claim from "./pages/Claim";
import About from "./pages/About";

// This is the chain your dApp will work on.
// Change this to the chain your app is built for.
// You can also import additional chains from `@thirdweb-dev/chains` and pass them directly.
const activeChain = "base";

const container = document.getElementById("root");
const root = createRoot(container);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Mint />
  },
  {
    path: "/claim",
    element: <Claim />
  },
  {
    path: "/about",
    element: <About />
  }
]);

root.render(
  <React.StrictMode>
    <ThirdwebProvider activeChain={activeChain}>
      <RouterProvider router={router} />
    </ThirdwebProvider>
  </React.StrictMode>
);
