import nft from "../assets/nft.gif"
import { useAddress, useContract, useContractWrite, Web3Button } from "@thirdweb-dev/react";
import { kitAddress, kitAbi, airportAddress, airportAbi, registryAddress, registryAbi } from "../abis/contracts";

const Card = (props) => {

    // initiate connected address and airport contract

    const connectedAddress = useAddress()
    const { contract: airportContract, isLoading: airportLoading } = useContract(airportAddress, airportAbi)
    const { contract: registryContract, isLoading: registryLoading } = useContract(registryAddress, registryAbi)

    const prepareClaim = async () => {

        // check for contract readiness and connected address

        if (connectedAddress && registryLoading === false && airportLoading === false) {

            // get tokenbound account of kit id

            const acc = await registryContract.call("account", ["0x2D25602551487C3f3354dD80D76D54383A243358", 8453, kitAddress, props.id, 0])
            console.log("Sending kit contents of account " + acc)

            // call mint on airport and sent contents to recipient

            await airportContract.call("claim", [acc, []])

        }

    }

    return (
        <div class="flex flex-col space-y-2 items-start p-4">

            {/* image preview */}

            <img src={nft} class="md:w-fit md:h-fit" />

            {/* kit id and buttons */}

            {/* <div class="bg-gray-100 border-t rounded-b-xl">
              
            </div> */}

            <h3 class="text-lg font-bold text-gray-600">
                KIT #{props.id}
            </h3>

            {/* claim button */}

            <Web3Button
                className="w-full"
                contractAddress={airportAddress}
                contractAbi={airportAbi}
                action={() => prepareClaim()}
                onSuccess={(result) => console.log("Success " + result)}
                onError={() => console.log("Transaction rejected")}
            >
                Claim
            </Web3Button>

        </div>
    );
}

export default Card;